<template>
  <v-form
    v-model="valid"
    class="my-0"
  >
    <v-autocomplete
      filled
      :label="$t('organization') + ' *'"
      v-model="value.organization"
      :items="organizations"
      item-value="uuid"
      :disabled="organization !== null"
      item-text="label"
      :no-data-text="$t('organizationNotFound')"
      :rules="[rules.required]"
    />
    <v-text-field
      filled
      :label="$t('title') + ' *'"
      v-model="value.title"
      :disabled="disabled"
      :rules="[rules.required]"
    />
    <v-text-field
      filled
      :label="$t('slug') + ' *'"
      v-model="value.slug"
      :disabled="disabled"
      :rules="[rules.required]"
    />
    <v-select
      filled
      :label="$t('type') + ' *'"
      :items="appProfileTypes"
      v-model="value.type"
      :rules="[rules.required]"
    />
    <v-text-field
      filled
      :label="$t('description') + ' *'"
      v-model="value.description"
      :disabled="disabled"
      :rules="[rules.required]"
    />
    <v-autocomplete
      filled
      label="Groups"
      v-model="value.groups"
      :items="groups"
      :loading="gettingYessGroups"
      :disabled="gettingYessGroups || disabled"
      item-value="slug"
      item-text="title"
      multiple
      small-chips
      deletable-chips
      no-data-text="No group(s) found"
      :filter="groupFilter"
    >
      <template v-slot:item="data">
        <span>
          <v-icon
            left
            v-if="data.attrs['aria-selected'] === 'true'"
          >mdi-checkbox-marked</v-icon>
          <v-icon
            left
            v-else
          >mdi-checkbox-blank-outline</v-icon>
          {{ data.item.title }} <span class="caption pl-3">{{ data.item.slug }}</span></span>
      </template>
      <template v-slot:prepend-item />
    </v-autocomplete>
    <v-text-field
      filled
      label="Book Table Url"
      v-model="value.bookTableUrl"
      :disabled="disabled"
    />
    <v-select
      :loading="items === null"
      filled
      label="Book Table Item"
      v-model="value.bookTableItem"
      :items="items"
      item-value="uuid"
      item-text="title"
      :disabled="disabled"
      clearable
    />
    <v-select
      :loading="channels === null"
      filled
      label="Channel"
      v-model="value.channel"
      :items="channels"
      item-value="uuid"
      item-text="label"
      :disabled="disabled"
    />
    <v-select
      :loading="locations === null"
      filled
      label="Location"
      v-model="value.location"
      :items="locations"
      item-value="uuid"
      item-text="label"
      :disabled="disabled"
    />
    <v-select
      :loading="categories === null"
      filled
      label="Categories"
      multiple
      v-model="value.categories"
      :items="categories"
      item-value="uuid"
      item-text="name"
      :disabled="disabled"
    />
    <v-autocomplete
      label="Allowed Payment Methods"
      v-model="value.paymentMethodInstances"
      clearable
      filled
      persistent-hint
      hint="Select checkout payment methods that can be used."
      :disabled="disabled || gettingOtherPaymentMethodInstances || gettingPaymentMethodInstanceList"
      :loading="gettingOtherPaymentMethodInstances || gettingPaymentMethodInstanceList"
      no-data-text="No active checkout payment methods were found."
      multiple
      small-chips
      deletable-chips
      :items="paymentMethodInstances"
      :item-text="paymentMethodInstanceTitle"
      item-value="uuid"
      return-object
    >
      <template v-slot:item="{ item }">
        <span><span>{{ item.title }} ({{ item.description }})</span> <span class="caption">[{{ item.namespace }}]</span></span>
      </template>
      <template v-slot:selection="{ item }">
        <span><span>{{ item.title }}</span> <span class="caption">[{{ item.namespace }}]</span></span>
      </template>
    </v-autocomplete>
    <v-select
      :loading="categories === null"
      filled
      label="Catering Categories"
      multiple
      v-model="value.cateringCategories"
      :items="categories"
      item-value="uuid"
      item-text="name"
      :disabled="disabled"
    />
    <v-select
      v-if="value.type === 'activity'"
      :loading="items === null"
      filled
      label="Available activities"
      multiple
      v-model="value.availableActivities"
      :items="eventActivityItems"
      item-value="uuid"
      item-text="title"
      :disabled="disabled"
      persistent-hint
      hint="If left empty then all activity/event items in selected categories will be shown in Yess."
    />
    <v-text-field
      filled
      label="Discount (%)"
      v-model="value.discountPercent"
      :disabled="disabled"
      type="number"
      min="0"
      max="100"
      persistent-hint
      hint="Can be between 0-100. Can also be left empty."
      clearable
    />
    <ColorSelect
      label="Primary color"
      v-model="value.primaryColor"
      clearable
      filled
      hint="If you do not set a color the primary color of the organization will be used"
      :disabled="disabled"
    />
    <ColorSelect
      label="Accent color"
      v-model="value.accentColor"
      clearable
      filled
      hint="If you do not set a color the accent color of the organization will be used"
      :disabled="disabled"
    />
    <v-autocomplete
      label="Other payment methods"
      v-model="value.otherPaymentMethodInstances"
      clearable
      filled
      persistent-hint
      hint="Select additional checkout payment methods that can be used."
      :disabled="disabled || gettingOtherPaymentMethodInstances || gettingPaymentMethodInstanceList"
      :loading="gettingOtherPaymentMethodInstances || gettingPaymentMethodInstanceList"
      no-data-text="No active checkout payment methods were found."
      multiple
      small-chips
      deletable-chips
      :items="paymentMethodInstances"
      :item-text="paymentMethodInstanceTitle"
      item-value="uuid"
      return-object
    >
      <template v-slot:item="{ item }">
        <span><span>{{ item.title }} ({{ item.description }})</span> <span class="caption">[{{ item.namespace }}]</span></span>
      </template>
      <template v-slot:selection="{ item }">
        <span><span>{{ item.title }}</span> <span class="caption">[{{ item.namespace }}]</span></span>
      </template>
    </v-autocomplete>
    <FileUpload
      label="Profile logo"
      v-model="value.logo"
      filled
      type="image"
      clearable
      hide-icon
      hint="The organization's logo will be used if left empty"
      @savedFile="saveLogo"
      @isSaving="setProcessing"
      :disabled="disabled"
    />
    <FileUpload
      label="Profile cover"
      v-model="value.cover"
      filled
      type="image"
      clearable
      hide-icon
      hint="The organization's cover image will be used if left empty"
      @savedFile="saveCover"
      @isSaving="setProcessing"
      :disabled="disabled"
    />
  </v-form>
</template>

<script>
import ColorSelect from "@/components/common/ColorSelect"
import FileUpload from "@/components/common/FileUpload"

export default {
  name: 'YessLinkForm',
  components: {
    FileUpload,
    ColorSelect
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    organization: {
      type: Object,
      default: () => {}
    },
    op: {
      type: String,
      default: ''
    },
    saving: {
      type: Boolean
    }
  },
  data() {
    return {
      channels: null,
      categories: null,
      locations: null,
      groups: [],
      items: [],
      types: ['food', 'activity'],
      gettingYessGroups: false,
      processing: false,
      gettingOtherPaymentMethodInstances: false,
      otherPaymentMethodInstances: [],
      valid: false
    }
  },
  computed: {
    organizations() {
      return this.$store.state.organizations
    },
    appProfileTypes() {
      return this.$store.state.appProfileTypes
    },
    disabled() {
      return this.saving || this.processing
    },
    gettingPaymentMethodInstanceList() {
      return this.$store.getters.gettingPaymentMethodInstanceList
    },
    eventActivityItems() {
      const availableActivityItems = this.items.filter(item => item && item.type === 'event' && item.status === true)
      if(Array.isArray(this.value.categories) && this.value.categories.length > 0) {
        const mappedValueCategories = this.value.categories.map(cat => cat.uuid).filter(notNull => notNull)
        return availableActivityItems.filter(item => item && Array.isArray(item.categories) && item.categories.length > 0 && item.categories.some(category => category && mappedValueCategories.includes(category.uuid)))
      }
      return availableActivityItems
    },
    paymentMethodInstances: {
      get() {
        return this.otherPaymentMethodInstances
      },
      set(value) {
        this.otherPaymentMethodInstances = value
      }
    },
    rules() {
      return this.$store.getters.rules
    }
  },
  methods: {
    paymentMethodInstanceTitle(item) {
      return item.title + ' (' + item.description + ')' + ' [' + item.namespace + ']'
    },
    selectOrganization(value) {
      this.value.organization = value
    },
    profileOrganization() {
      return this.value.organization
    },
    saveLogo(file) {
      this.value.logo = file
    },
    saveCover(file) {
      this.value.cover = file
    },
    setProcessing(status) {
      this.processing = status
    },
    getChannels() {
      this.$store.dispatch('getChannelsByOrganizationUUID', this.organization.uuid).then(channels => {
        this.channels = channels
      })
    },
    getCategories() {
      this.$store.dispatch('getCategoriesByOrganizationUUID', this.organization.uuid).then(categories => {
        this.categories = categories
      })
    },
    getLocations() {
      this.$store.dispatch('getLocationsByOrganizationUUID', this.organization.uuid).then(locations => {
        this.locations = locations
      })
    },
    getItems() {
      this.$store.dispatch('getItemsByOrganizationUUID', this.organization.uuid).then(items => {
        if(items === null || items === undefined) {
          this.items = []
        }
        this.items = items.filter(item => item && item.type === 'event')
      })
    },
    getYessGroups() {
      this.gettingYessGroups = true
      this.$store.dispatch('getYessGroups').then(groups => {
        this.groups = groups
      }).finally(() => {
        this.gettingYessGroups = false
      })
    },
    getOtherPaymentMethods() {
      this.gettingOtherPaymentMethodInstances = true
      const payload = {
        organizationUUID: this.organization.uuid,
        terminal: false,
        checkout: true
      }
      return this.$store.dispatch('getYessOrganizationPaymentMethodInstanceList', payload).then(result => {
        this.paymentMethodInstances = result
      }).catch(() => {
        this.paymentMethodInstances = []
      }).finally(() => {
        this.gettingOtherPaymentMethodInstances = false
      })
    },
    groupFilter(group, queryText) {
      if(group === null || group === undefined || queryText === null || queryText === undefined) {
        return group
      }

      const title = group.title ? group.title.toLowerCase() : ''
      const slug = group.slug ? group.slug.toLowerCase() : ''
      const query = queryText.toLowerCase()

      return title.indexOf(query) > -1 || slug.indexOf(query) > -1
    }
  },
  mounted() {

		if(this.organization !== undefined && this.organization !== null && this.organization.uuid !== undefined && this.organization.uuid !== null) {
			this.getChannels()
      this.getCategories()
      this.getLocations()
      this.getItems()
      this.getOtherPaymentMethods()
    }
    this.getYessGroups()
  },
  watch: {
		organization(value) {
			if(value && value.uuid) {
				this.channels = null
				this.$store.dispatch('getChannelsByOrganizationUUID', value.uuid).then(channels => {
					this.channels = channels
				})
				this.categories = null
				this.$store.dispatch('getCategoriesByOrganizationUUID', value.uuid).then(categories => {
					this.categories = categories
				})
				this.locations = null
				this.$store.dispatch('getLocationsByOrganizationUUID', value.uuid).then(locations => {
					this.locations = locations
				})
				this.items = null
				this.$store.dispatch('getItemsByOrganizationUUID', value.uuid).then(items => {
					if(items === null || items === undefined) {
						this.items = []
					}
					this.items = items.filter(item => item && item.type === 'event')
				})
				this.gettingOtherPaymentMethodInstances = true
				const payload = {
					organizationUUID: value.uuid,
					terminal: false,
					checkout: true
				}
				return this.$store.dispatch('getYessOrganizationPaymentMethodInstanceList', payload).then(result => {
					this.paymentMethodInstances = result
				}).catch(() => {
					this.paymentMethodInstances = []
				}).finally(() => {
					this.gettingOtherPaymentMethodInstances = false
				})
			}
		},
    processing(value) {
      this.$emit('processing', value)
    },
    valid(value) {
      this.$emit('valid', value)
    },
    'value.organization'(uuid) {
      this.$emit('organizationSelected', uuid)
      this.$store.dispatch('getChannelsByOrganizationUUID', uuid).then(channels => {
        this.channels = channels
      })
      this.$store.dispatch('getCategoriesByOrganizationUUID', uuid).then(categories => {
        this.categories = categories
      })
      this.$store.dispatch('getLocationsByOrganizationUUID', uuid).then(locations => {
        this.locations = locations
      })
      this.$store.dispatch('getItemsByOrganizationUUID', uuid).then(items => {
        if(items === null || items === undefined) {
          this.items = []
        }
        this.items = items.filter(item => item && item.type === 'event')
      })
      this.gettingOtherPaymentMethodInstances = true
      const payload = {
        organizationUUID: uuid,
        terminal: false,
        checkout: true
      }
      return this.$store.dispatch('getYessOrganizationPaymentMethodInstanceList', payload).then(result => {
        this.paymentMethodInstances = result
      }).catch(() => {
        this.paymentMethodInstances = []
      }).finally(() => {
        this.gettingOtherPaymentMethodInstances = false
      })
    },
    organizationInput(uuid) {
      this.$emit('organizationSelected', uuid)
      this.$store.dispatch('getChannelsByOrganizationUUID', uuid).then(channels => {
        this.channels = channels
      })
      this.$store.dispatch('getCategoriesByOrganizationUUID', uuid).then(categories => {
        this.categories = categories
      })
      this.$store.dispatch('getLocationsByOrganizationUUID', uuid).then(locations => {
        this.locations = locations
      })
      this.$store.dispatch('getItemsByOrganizationUUID', uuid).then(items => {
        if(items === null || items === undefined) {
          this.items = []
        }
        this.items = items.filter(item => item && item.type === 'event')
      })
      this.gettingOtherPaymentMethodInstances = true
      const payload = {
        organizationUUID: uuid,
        terminal: false,
        checkout: true
      }
      return this.$store.dispatch('getYessOrganizationPaymentMethodInstanceList', payload).then(result => {
        this.paymentMethodInstances = result
      }).catch(() => {
        this.paymentMethodInstances = []
      }).finally(() => {
        this.gettingOtherPaymentMethodInstances = false
      })
    }
  }
}
</script>
