<template>
  <v-file-input
    v-model="file"
    :disabled="disabled || saving"
    :loading="loading || saving"
    :label="label"
    :prepend-icon="hideIcon ? '' : (icon ? icon : (type === 'image' ? 'mdi-camera' : 'mdi-paperclip'))"
    :multiple="multiple"
    :filled="filled"
    :accept="accept"
    show-size
    :clearable="clearable"
    :persistent-hint="!!hint"
    :hint="hint"
    @change="updateFile"
    @click:clear="removeFile"
  />
</template>

<script>
import axios from 'axios'

/**
 * type prop only supports image at the moment or a very general file upload
 */

export default {
	name: "FileUpload",
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		multiple: {
			type: Boolean
		},
		filled: {
			type: Boolean
		},
		icon: {
			type: String,
			default: ''
		},
		hideIcon: {
			type: Boolean,
		},
		type: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
		},
		clearable: {
			type: Boolean,
		},
		hint: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			saving: false
		}
	},
	computed: {
		graphUrl() {
			return this.$store.state.graphUrl
		},
		file: {
			get() {
				if(this.value === null || this.value === undefined) {
					return null
				}

				let options = {}
				if(this.type) {
					options.type = this.accept
				}

				if(Array.isArray(this.value)) {
					let files = []
					for(let i = 0; i < this.value.length; i++) {
						let file = this.value[i]
						if(file === null || file === undefined || file.filename === null || file.filename === undefined) {
							continue
						}

						files.push(new File([''], file.filename, options))
					}

					return files
				}

				if(this.value.url === null || this.value.url === undefined) {
					return null
				}

				return new File([''], this.value.filename, options)
			},
			set(file) {
				if(file === null || typeof file !== 'object' || file.size === 0 || file.size > 2048000) {
					return
				}

				let fileReader = new FileReader()
				fileReader.onload = function(e) {
					this.saveFile({
						filename: file.name,
						base64Data: e.target.result
					})
				}.bind(this)

				fileReader.readAsDataURL(file)
			}
		},
		accept() {
			if(this.type === 'image') {
				return 'image/png'
			} else {
				return ''
			}
		}
	},
	methods: {
		removeFile() {
			this.$emit('savedFile', null)
		},
    updateFile(file) {
		  this.file = file
    },
		saveFile(file) {
			this.saving = true
			this.$emit('isSaving', true)
			let body = {
				query: 'mutation {\n' +
						'   saveFile(file: {' +
						'       filename: "' + file.filename + '", ' +
						'       base64Data: "' + file.base64Data + '", ' +
						'       public: true, global: true }) {\n' +
						'     filename\n' +
						'     url\n' +
						'     mime\n' +
						'     size\n' +
						'     created\n' +
						'     changed\n' +
						'   }\n' +
						'}'
			}
			return axios.post(this.graphUrl, body, {
        params: {
          time: new Date().getTime()
        }
      }).then(response => {
				const saveFileResponse = response.data.data.saveFile

				if(saveFileResponse) {
					this.$emit('savedFile', saveFileResponse)
					return saveFileResponse.filename
				}
			}).finally(() => {
				this.saving = false
				this.$emit('isSaving', false)
			})
		}
	}
}
</script>

<style scoped>

</style>
